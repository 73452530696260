


import React from "react"
import Scrollspy from "react-scrollspy"
import { Nav } from "react-bootstrap"
import Scroller from './scroller'
import Logo from '../images/logo-white.svg'

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this);
    }

    render() {
        return (
            <footer className="text-white py-5" id="footer">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-5 col-md-2 mb-3">
                            <img src={Logo} alt="logo" className="img-fluid mb-3 logo" />
                            <a href="tel:18662932511">1 866 293 2511</a>
                        </div>

                        <div className="col-10 col-md-3 text-white mb-3">
                            <a className="btn btn-secondary btn-xl mt-2" href="mailto:mailto:emorin@visionmeteo.com">Demander de l'information</a>
                        </div>

                        <div className="col-12 col-md-5 text-white nav">
                            <Scrollspy
                                items={["about", "services", "portfolio", "contact"]}
                                currentClassName="active" rootEl={"#mainNav"} offset={-75}
                            >
                                <li className="nav-item">
                                    <Nav.Link className={"js-scroll-trigger"} href="#mission" onClick={Scroller.handleAnchorScroll}>Mission</Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link className={"js-scroll-trigger"} href="#expertise" onClick={Scroller.handleAnchorScroll}>Expertise</Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link className={"js-scroll-trigger"} href="#application" onClick={Scroller.handleAnchorScroll}>App Météo</Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link className={"js-scroll-trigger"} href="#donnees" onClick={Scroller.handleAnchorScroll}>Données</Nav.Link>
                                </li>

                                <li className="nav-item">
                                    <Nav.Link className={"js-scroll-trigger"} href="#medias" onClick={Scroller.handleAnchorScroll}>Médias</Nav.Link>
                                </li>

                                <li className="nav-item">
                                    <Nav.Link href="#contact" href="mailto:emorin@visionmeteo.com">Nous joindre</Nav.Link>
                                </li>
                            </Scrollspy>
                        </div>

                        <div className="col-12 col-md-2 text-white">
                            <a className="" href="https://www.linkedin.com/in/visionmeteo" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="31.167" viewBox="0 0 33 31.167">
                                    <path id="linkedin-fill" d="M11.335,7.963A3.463,3.463,0,1,1,7.874,4.5a3.463,3.463,0,0,1,3.461,3.464Zm.1,6.025H4.514V35.665h6.925Zm10.942,0H15.491V35.665h6.822V24.29c0-6.337,8.259-6.925,8.259,0V35.665H37.41V21.935c0-10.683-12.223-10.284-15.1-5.038Z" transform="translate(-4.41 -4.499)" fill="#fff" />
                                </svg>
                            </a>
                        </div>

                    </div>
                </div>
            </footer>
        );
    }
}
